main {
  aside {
    &.search {
      display: none;
      grid-column-end: span 12;
      [role="button"] {
        cursor: pointer;
        position: absolute;
        right: 1rem;
        display: block;
        margin: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        svg {
          pointer-events: none;
        }
      }
    }
  }
  .search-results {
    div {
      h3,
      p {
        text-align: left;
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
  }
}
.page {
  &.listing {
    main {
      aside {
        &.search {
          order: 1;
        }
      }
    }
  }
}

header {
  form[role="search"] {
    display: flex;
    position: relative;
    width: 100%;
    height: 2.625rem;
    margin: 0;
    overflow: hidden;
    input[type="search"] {
      flex: 1 0 0%;
      padding: 0;
      width: auto;
      min-width: 4rem;
      height: 2.625rem;
      margin-left: 0.75rem;
      font-size: 100%;
      line-height: 1.15;
      text-overflow: ellipsis;
      white-space: nowrap;
      border: 0;
      overflow: hidden;
      outline: none;
      z-index: 10;
      transition: all 0.4s ease;
    }
    label {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: auto 0;
      padding: 0.5rem 0.75rem;
      background: transparent;
      border: 0;
      cursor: pointer;
      user-select: none;
    }
  }
}
