input,
textarea {
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 var(--formbase__margin);
  padding: var(--formbase__padding);
  background: var(--formbase__background);
  border: 1px solid var(--formbase__border);
  box-shadow: var(--formbase__shadow);
  border-radius: var(--formbase__radius);
  outline: none;
  resize: vertical;
  transition: border-color var(--formbase__duration var(--formbase__timing));
  color: var(--formbase__color);
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  &:disabled {
    border-color: var(--formbase__disabled_border);
    background-color: var(--formbase__disabled_background);
    box-shadow: none;
    cursor: not-allowed;
    &::placeholder {
      color: var(--formbase__disabled_placeholder);
    }
  }
  &:focus {
    border-color: var(--formbase__active);
  }
  &::placeholder {
    color: var(--formbase__placeholder);
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: var(--formbase__placeholder);
  }
  &::-ms-input-placeholder {
    color: var(--formbase__placeholder);
  }
}
