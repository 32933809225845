aside.next-previous {
  display: none;
  grid-gap: 0;
  grid-template-columns: repeat(12, 1fr);
  grid-column-end: span 12;
  flex: 1;
  align-content: flex-end;
  article {
    grid-column-end: span 12;
    padding: 0.5rem 1.5rem;
    &:first-of-type {
      h2 {
        text-align: left;
      }
    }
    &:last-of-type {
      h2 {
        text-align: right;
      }
    }
    h2 {
      margin: 0.5rem 0;
      font-size: calc(1.424rem * 1.1);
    }
    svg {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      vertical-align: middle;
    }
  }
}
