header[role="banner"] {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  h1 {
    margin: 0;
    padding: 1rem;
    font-family: var(--Raleway);
    font-size: 0.825rem;
    font-weight: 800;
    letter-spacing: 0.25rem;
    text-transform: uppercase;
    order: 0;
    a {
      padding: 1rem;
      border-right: solid 1px transparent;
    }
  }
  .links {
    display: none;
    flex: 1;
    flex-basis: 40%;
    width: 100%;
    min-width: 0;
    max-width: 100%;
    max-height: 3rem;
    overflow: hidden;
    > div {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      a {
        position: relative;
        width: 100%;
        padding: 1rem 0.5rem;
        font-family: var(--Raleway);
        font-size: 0.825rem;
        font-weight: 400;
        line-height: 1;
        text-transform: uppercase;
        &:before {
          border-right: solid 1px transparent;
        }
      }
    }
  }
  .menu {
    display: flex;
    flex-basis: 0;
    justify-content: flex-end;
    width: calc(100% - 1rem);
    margin: 0 auto;
  }
  [role="button"] {
    margin: auto 0.5rem auto 0;
    cursor: pointer;
  }
  svg {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    pointer-events: none;
  }
  [mobile-menu-open] {
    margin-left: 0.5rem;
  }
}
