/* @see https://github.com/vladocar/SMART-CSS-GRID/ */
.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin: 0 auto;
  height: 100%;
  height: 100vh;
}

.one,
.two,
.three,
.four,
.five,
.six,
.seven,
.eight,
.nine,
.ten,
.eleven,
.twelve {
  grid-column-end: span 12;
}

.nested {
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(12, 1fr);
}

.merge-two-rows {
  grid-row-end: span 2;
}

.merge-three-rows {
  grid-row-end: span 3;
}

.merge-four-rows {
  grid-row-end: span 4;
}

.merge-five-rows {
  grid-row-end: span 5;
}

.merge-six-rows {
  grid-row-end: span 6;
}

.row-max-middle {
  grid-template-rows: auto 1fr auto;
}

@media (min-width: 320px) {
  .one {
    grid-column-end: span 1;
  }

  .two {
    grid-column-end: span 2;
  }

  .three {
    grid-column-end: span 3;
  }

  .four {
    grid-column-end: span 4;
  }

  .five {
    grid-column-end: span 5;
  }

  .six {
    grid-column-end: span 6;
  }

  .seven {
    grid-column-end: span 7;
  }

  .eight {
    grid-column-end: span 8;
  }

  .nine {
    grid-column-end: span 9;
  }

  .ten {
    grid-column-end: span 10;
  }

  .eleven {
    grid-column-end: span 11;
  }
}
