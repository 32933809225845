input[type="radio"],
input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  margin: 0 0 var(--formbase__margin);
  box-sizing: border-box;
}
input[type="radio"] ~ label,
input[type="checkbox"] ~ label {
  display: flex;
  align-items: center;
  position: relative;
  color: var(--formbase__color);
  &::before,
  &::after {
    content: "";
    display: block;
    width: var(--formbase__control_size);
    height: var(--formbase__control_size);
    border: 1px solid transparent;
  }
  &::before {
    flex-shrink: 0;
    margin-right: var(--formbase__padding);
    border-color: var(--formbase__border);
    box-shadow: var(--formbase__shadow);
    background: var(--formbase__background);
  }
  &::after {
    position: absolute;
    top: calc(48% - var(--formbase__control_size) / 2);
    left: 0;
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
    transform: scale(0);
    transition: transform var(--formbase__duration) var(--formbase__timing);
  }
}
input[type="radio"] + label::before {
  border-radius: 100%;
}
input[type="radio"] + label::after {
  background-image: url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22512%22%20height%3D%22512%22%20viewBox%3D%220%200%20512%20512%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22black%22%20d%3D%22M256%2C464c114.9%2C0%2C208-93.1%2C208-208c0-114.9-93.1-208-208-208C141.1%2C48%2C48%2C141.1%2C48%2C256C48%2C370.9%2C141.1%2C464%2C256%2C464z%22/%3E%3C/svg%3E");
}
input[type="checkbox"] + label::after {
  background-image: url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22512%22%20height%3D%22512%22%20viewBox%3D%220%200%20512%20512%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22black%22%20d%3D%22M461.6%2C109.6l-54.9-43.3c-1.7-1.4-3.8-2.4-6.2-2.4c-2.4%2C0-4.6%2C1-6.3%2C2.5L194.5%2C323c0%2C0-78.5-75.5-80.7-77.7c-2.2-2.2-5.1-5.9-9.5-5.9c-4.4%2C0-6.4%2C3.1-8.7%2C5.4c-1.7%2C1.8-29.7%2C31.2-43.5%2C45.8c-0.8%2C0.9-1.3%2C1.4-2%2C2.1c-1.2%2C1.7-2%2C3.6-2%2C5.7c0%2C2.2%2C0.8%2C4%2C2%2C5.7l2.8%2C2.6c0%2C0%2C139.3%2C133.8%2C141.6%2C136.1c2.3%2C2.3%2C5.1%2C5.2%2C9.2%2C5.2c4%2C0%2C7.3-4.3%2C9.2-6.2L462%2C121.8c1.2-1.7%2C2-3.6%2C2-5.8C464%2C113.5%2C463%2C111.4%2C461.6%2C109.6z%22/%3E%3C/svg%3E");
}
input[type="radio"]:disabled + label,
input[type="checkbox"]:disabled + label {
  cursor: not-allowed;
  color: var(--formbase__disabled_placeholder);
}
input[type="radio"]:disabled + label::before,
input[type="checkbox"]:disabled + label::before {
  border-color: var(--formbase__disabled_border);
  background-color: var(--formbase__background);
  box-shadow: none;
}
input:focus + label::before {
  border-color: var(--formbase__active);
}
input:checked + label::after {
  transform: scale(1);
}
