@import "../components/search.pcss";
@import "../components/metadata.pcss";

.page {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: repeat(12, 1fr);
  margin: 0 auto;
  height: 100%;
  height: 100vh;
  &:not(.listing) {
    main,
    .main {
      width: 100%;
      max-width: 120rem;
    }
  }
  header {
    grid-column-end: span 12;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    h1 {
      margin: 0;
      padding: 1rem;
      font-family: var(--Raleway);
      font-weight: 800;
      letter-spacing: 0.15rem;
      text-transform: uppercase;
      order: 0;
      a {
        padding: 1rem;
      }
    }
  }
  header[role="banner"] .links > div a {
    &:not(:last-of-type):after {
      content: "";
      position: absolute;
      left: 0.4rem;
      right: 0.4rem;
      bottom: 0.4rem;
      height: 0.188rem;
      opacity: 0;
      transition-property: opacity;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
    }
    &:hover,
    &.active,
    &:focus {
      &:after {
        opacity: 1;
      }
    }
  }
  main,
  .main {
    grid-column-end: span 12;
    max-width: 1920px;
    section {
      margin: 2rem 0 0 0;
    }
  }
  article {
    width: 80%;
    margin: 2rem auto;
    padding: 2rem 4rem;
    header {
      display: flex;
      flex-direction: column;
      h1 {
        margin: 3rem auto 4rem auto;
      }
    }
    .metadata {
      margin: 0 0 2rem 0;
    }
  }
  > footer {
    width: 80%;
    grid-column-end: span 12;
    margin: 2rem auto 0 auto;
    padding: 1rem 2rem;
    text-align: center;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    max-width: calc(100% - 2rem);
    margin: 2rem 0 1rem 0;
    font-family: var(--Raleway);
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    a {
      padding: 2rem 0;
    }
  }
  aside {
    &.search {
      grid-column-end: span 12;
      position: relative;
      margin: 0;
      padding: 2rem 4rem 1rem;
    }
  }
  aside.next-previous {
    width: 80%;
    margin: 0 auto 0;
    article {
      width: 100%;
      margin: 0;
    }
  }
}
.page.listing {
  main {
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: repeat(12, 1fr);
    grid-column-end: span 12;
    width: 80%;
    margin: 0 auto 0;
    section {
      order: 1;
      grid-column-end: span 9;
    }
    article {
      width: 100%;
      margin: 0 0 3rem 0;
      padding: 0;
      header {
        a {
          display: flex;
          flex-direction: column;
          width: 100%;
          border-bottom: 0;
          h1,
          h2 {
            order: 1;
            margin: 2.5rem auto 1rem;
          }
          h3 {
            order: 2;
          }
          img {
            order: 0;
            width: 100%;
            height: 20rem;
            object-fit: cover;
            object-position: center;
          }
        }
      }
      section {
        margin: 0;
        padding: 2rem 4rem 1rem;
      }
      footer {
        margin: 0 0.5rem;
        padding: 1rem 4rem;
        font-family: var(--OpenSans);
        font-size: 0.8rem;
        letter-spacing: 0.15rem;
        white-space: normal;
        p {
          word-break: break-word;
        }
      }
    }
    aside {
      order: 0;
      grid-column-end: span 3;
      > header {
        display: flex;
        flex-direction: column;
        > img,
        svg {
          display: block;
          width: 5rem;
          height: 5rem;
          margin: 1rem auto 1rem 0;
        }
        > h1 {
          width: 100%;
          max-width: 100%;
          padding: 1rem 0;
          text-align: left;
          word-break: break-word;
        }
        > p {
          width: 100%;
          font-family: var(--Raleway);
          font-size: 1.25rem;
        }
      }
      section {
        article {
          display: flex;
          flex-direction: column;
          padding: 0;
          header {
            h1,
            h2 {
              padding: 1rem 1rem 0 1rem;
              margin: 0;
              width: 100%;
              font-size: 2rem;
              text-align: center;
            }
            img {
              height: 10rem;
            }
          }
          section,
          footer {
            margin: 0;
            padding: 0.5rem 1rem;
          }
        }
      }
    }
  }
  &.blog {
    article.search {
      grid-column-end: span 12;
      padding: 2rem 4rem;
    }
  }
}
.page.article.tufte {
  padding-left: 0;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    clear: both;
    text-align: left;
  }
  main {
    width: 100%;
    article {
      section {
        p {
          width: 65%;
        }
        .marginnote,
        .sidenote {
          width: 50% !important;
          margin-right: -55%;
          padding: 0;
        }
      }
    }
  }
  .active,
  a:active,
  a:focus,
  a:hover,
  a:link,
  a:visited {
    background: 0;
  }
}
