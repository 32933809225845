.docs {
  main {
    > header[role="banner"] {
      .links {
        order: 1;
        > div {
          a {
            font-size: 1.125rem;
            text-transform: none;
          }
        }
      }
      .menu {
        order: 2;
        .edit-link {
          margin: auto 0;
          margin: auto 1rem auto 0;
          cursor: pointer;
        }
      }
    }
  }
}
