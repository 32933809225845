.docs {
  .sidebar {
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    line-height: 1;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: inherit;
      margin: 0;
      padding: 0.5rem 1rem 0.5rem 1rem;
    }
    header,
    footer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      padding: 0.5rem;
      h1 {
        flex: 0 0 90%;
        margin: 0.5rem 0;
        font-size: 2.15rem;
        text-align: center;
      }
    }
    .menu {
      ol {
        padding: 0;
        padding-inline-start: 0;
        counter-reset: item;
        list-style-position: inside;
        li {
          display: block;
          margin: 0.25rem 0 0 0;
          padding: 0.5rem 1rem 0.5rem 1rem;
          &:before {
            content: counters(item, ".") " ";
            counter-increment: item;
          }
          > ol {
            margin: 0.5rem 0 0 0;
          }
        }
      }
      > ol > .active {
        padding: 0.5rem 0 0.5rem 1rem;
      }
      .current {
        padding-bottom: 0.5rem;
      }
      .current-group {
        padding-top: 0.5rem;
      }
      &.what-links-here {
        ol {
          margin-top: 0;
        }
      }
    }
    footer {
      flex: 1;
      flex-direction: column;
      font-size: 1rem;
      section {
        flex: 1;
        width: 100%;
        a {
          display: block;
        }
      }
      svg {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1;
        vertical-align: middle;
      }
    }
    > [role="button"] {
      position: absolute;
      top: 0.6rem;
      left: 1rem;
      cursor: pointer;
      svg {
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        pointer-events: none;
      }
    }
  }
}
