@import "./root.pcss";
@import "./normalize.pcss";
@import "../../css/fonts.css";
@import "./grid.pcss";
@import "./page/page.pcss";
@import "./book/book.pcss";
@import "./docs/docs.pcss";
@import "./components/cv.pcss";
@import "./components/header.pcss";
@import "./components/drawer.pcss";
@import "./components/related.pcss";
@import "./components/formbase/main.pcss";
@import "./components/selectr.pcss";
@import "./components/sequence.pcss";
@import "./responsive.pcss";

body {
  font-family: var(--OpenSans);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
  text-rendering: optimizeLegibility;
  counter-reset: sidenote-counter;
}

h1 {
  font-size: 1.802rem;
}
h2 {
  font-size: 1.602rem;
}
h3 {
  font-size: 1.424rem;
}
h4 {
  font-size: 1.266rem;
}
h5 {
  font-size: 1.125rem;
}
h6 {
  font-size: 1rem;
}

b,
strong {
  font-weight: 600;
}

a,
a:link,
a:visited,
a:focus,
a:active {
  text-decoration: none;
}
a[href] {
  cursor: pointer;
}

blockquote {
  font-style: italic;
}

figure {
  margin: 0;
  text-align: center;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  line-height: 1.5;
}

code,
kbd,
samp {
  word-break: break-word;
  overflow-wrap: break-word;
  overflow: auto;
}
pre {
  word-break: break-all;
  code {
    white-space: pre-wrap;
  }
  &.sf-dump {
    grid-column-end: span 12;
  }
}

main,
.main {
  width: 100%;
  margin: 0 auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  thead {
    border-bottom: 2px solid transparent;
  }
  tr {
    border-bottom: 1px solid transparent;
  }
  th,
  td {
    padding: 0.25rem;
    text-align: left;
  }
}

.block {
  display: block;
}

/* Itemize */
article > p[aria-label],
article > img[aria-label],
article > figure[aria-label] {
  position: relative;
  &:before {
    content: attr(aria-label);
    position: absolute;
    left: -3.75rem;
    opacity: 0.65;
    width: 3.5rem;
    max-width: 3.5rem;
    text-align: right;
    word-break: break-word;
  }
}

/* Notes */
label.margin-toggle {
  width: auto;
  &:not(.sidenote-number) {
    display: none;
  }
}
.marginnote,
.sidenote {
  display: block;
  width: 100%;
  padding: 1rem;
  font-family: var(--Raleway);
  line-height: 1.3;
  position: relative;
}
.sidenote-number {
  counter-increment: sidenote-counter;
  &:after {
    content: counter(sidenote-counter);
    font-size: 1rem;
    top: -0.5rem;
    left: 0.1rem;
  }
}
.sidenote-number:after,
.sidenote:before {
  font-family: et-book-roman-old-style;
  position: relative;
  vertical-align: baseline;
}
label.sidenote-number,
input[type="checkbox"] ~ label.sidenote-number,
input.margin-toggle,
input[type="checkbox"] ~ input.margin-toggle {
  display: none;
  border: 0;
}

/* Slider */
.swiper-container {
  max-height: 80vh;
  .swiper-wrapper {
    height: 100%;
    .swiper-slide {
      width: 100%;
      height: 60vh;
      min-height: 40vh;
      max-height: 80%;
      max-height: 80vh;
      text-align: center;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
