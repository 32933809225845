.metadata {
  dd,
  dt {
    margin: 0;
    ol {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        margin: 0 0 0 1rem;
      }
    }
  }
}
