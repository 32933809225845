/* raleway-300 - latin-ext_latin */
@font-face {
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  src: local("Raleway Light"), local("Raleway-Light"),
    url("fonts/raleway-v13-latin-ext_latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/raleway-v13-latin-ext_latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* raleway-300italic - latin-ext_latin */
@font-face {
  font-family: Raleway;
  font-style: italic;
  font-weight: 300;
  src: local("Raleway Light Italic"), local("Raleway-LightItalic"),
    url("fonts/raleway-v13-latin-ext_latin-300italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/raleway-v13-latin-ext_latin-300italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* raleway-regular - latin-ext_latin */
@font-face {
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  src: local(Raleway), local("Raleway-Regular"),
    url("fonts/raleway-v13-latin-ext_latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/raleway-v13-latin-ext_latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* raleway-italic - latin-ext_latin */
@font-face {
  font-family: Raleway;
  font-style: italic;
  font-weight: 400;
  src: local("Raleway Italic"), local("Raleway-Italic"),
    url("fonts/raleway-v13-latin-ext_latin-italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/raleway-v13-latin-ext_latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* raleway-600 - latin-ext_latin */
@font-face {
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  src: local("Raleway SemiBold"), local("Raleway-SemiBold"),
    url("fonts/raleway-v13-latin-ext_latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/raleway-v13-latin-ext_latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* raleway-600italic - latin-ext_latin */
@font-face {
  font-family: Raleway;
  font-style: italic;
  font-weight: 600;
  src: local("Raleway SemiBold Italic"), local("Raleway-SemiBoldItalic"),
    url("fonts/raleway-v13-latin-ext_latin-600italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/raleway-v13-latin-ext_latin-600italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* raleway-800 - latin-ext_latin */
@font-face {
  font-family: Raleway;
  font-style: normal;
  font-weight: 800;
  src: local("Raleway ExtraBold"), local("Raleway-ExtraBold"),
    url("fonts/raleway-v13-latin-ext_latin-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/raleway-v13-latin-ext_latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* raleway-800italic - latin-ext_latin */
@font-face {
  font-family: Raleway;
  font-style: italic;
  font-weight: 800;
  src: local("Raleway ExtraBold Italic"), local("Raleway-ExtraBoldItalic"),
    url("fonts/raleway-v13-latin-ext_latin-800italic.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/raleway-v13-latin-ext_latin-800italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-regular - cyrillic-ext_latin-ext_cyrillic_vietnamese_latin */
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("fonts/montserrat-v13-cyrillic-ext_latin-ext_cyrillic_vietnamese_latin-regular.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/montserrat-v13-cyrillic-ext_latin-ext_cyrillic_vietnamese_latin-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-300 - cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-300.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-300.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-300italic - cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: local("Open Sans Light Italic"), local("OpenSans-LightItalic"),
    url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-300italic.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-300italic.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-regular - cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-regular.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-regular.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-600.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-600.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-italic - cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"), local("OpenSans-Italic"),
    url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-italic.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-italic.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600italic - cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local("Open Sans SemiBold Italic"), local("OpenSans-SemiBoldItalic"),
    url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-600italic.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-600italic.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700italic - cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"),
    url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-700italic.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-700italic.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-700.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-700.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-800 - cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
    url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-800.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-800.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-800italic - cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: local("Open Sans ExtraBold Italic"), local("OpenSans-ExtraBoldItalic"),
    url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-800italic.woff2")
      format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("fonts/open-sans-v16-cyrillic-ext_greek-ext_latin-ext_cyrillic_vietnamese_greek_latin-800italic.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
